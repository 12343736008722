import React, { FunctionComponent, useMemo } from 'react'
import { marked } from 'marked'

import classes from './MarkdownRenderer.module.scss'

export type MarkdownRendererProps = {
  className?: string
  content: string
}

export const MarkdownRenderer: FunctionComponent<MarkdownRendererProps> = ({ className, content }) => {
  const html = useMemo(() => ({ __html: marked.parse(content) }), [content])

  return <div className={cn(classes.markdown, className)} dangerouslySetInnerHTML={html} />
}
