export interface PressArticle {
  id: string
  logo: string
  logo_size: [width: number, height: number]
  title: string
  link: string
  date: string
}

export const PRESS_ARTICLES: PressArticle[] = [
  {
    id: 'rusbase',
    logo: require('@app/assets/press/rusbase.svg?force_file'),
    logo_size: [145, 40],
    title: 'Как сервис по\u00a0подбору бебиситтеров Kidsout перестроился в\u00a0период пандемии',
    link: 'https://rb.ru/story/kidsout-reboot/',
    date: '2020-06-11',
  },

  {
    id: 'village',
    logo: require('@app/assets/press/village.svg?force_file'),
    logo_size: [200, 150],
    title: 'Кто и\u00a0как организует сервис онлайн-нянь',
    link: 'https://www.the-village.ru/village/children/deti/381259-onlayn-nyani',
    date: '2020-05-20',
  },

  {
    id: 'vc',
    logo: require('@app/assets/press/vc.svg?force_file'),
    logo_size: [140, 105],
    title: 'Airbnb для родителей и\u00a0бебиситтеров',
    link: 'https://vc.ru/story/33413-kidsout',
    date: '2018-04-02',
  },

  {
    id: 'thesymbol',
    logo: require('@app/assets/press/thesymbol.svg?force_file'),
    logo_size: [572, 160],
    title: 'Нужны ли материнские жертвы и\u00a0кто такой бебиситтер: как воспитывают детей сегодня',
    link: 'https://www.thesymbol.ru/heroes/mnenie/lyubit-nelzya-vospityvat-chto-znachit-byt-roditelem-segodnya/',
    date: '2017-09-26',
  },

  {
    id: 'nen',
    logo: require('@app/assets/press/nen.svg?force_file'),
    logo_size: [200, 150],
    title: '«Ситтер должен быть веселый, чтобы дети с\u00a0ним не умерли со\u00a0скуки»',
    link: 'https://n-e-n.ru/funnybabysitter/',
    date: '2016-12-08',
  },

  // {
  //   id: 'novaya',
  //   logo: require('@app/assets/press/novaya.svg?force_file'),
  //   logo_size: [180, 135],
  //   title: 'Присмотри за\u00a0ним, если сможешь',
  //   link: 'https://www.novayagazeta.ru/articles/2015/02/27/63218-prismotri-za-nim-esli-smozhesh',
  //   date: '2015-03-06',
  // },

  {
    id: 'village2',
    logo: require('@app/assets/press/village.svg?force_file'),
    logo_size: [200, 150],
    title: 'Усы и\u00a0дети: Виктор Фещенко пробует себя в\u00a0роли бебиситтера',
    link: 'https://www.the-village.ru/village/children/children-exp/169671-kids-are-awesome',
    date: '2014-11-18',
  },

  {
    id: 'afisha',
    logo: require('@app/assets/press/afisha.png?force_file'),
    logo_size: [417, 64],
    title: 'Каково это\u00a0— быть бебиситтером',
    link: 'https://daily.afisha.ru/archive/gorod/people/kakovo-eto-byt-bebisitterom/',
    date: '2014-10-29',
  },

  {
    id: 'theoryandpractice',
    logo: require('@app/assets/press/theoryandpractice.svg?force_file'),
    logo_size: [191, 20],
    title: 'Первая помощь родителям: чему будут учить в\u00a0школе бебиситтеров Kidsout',
    link: 'https://theoryandpractice.ru/posts/8578--babysitter',
    date: '2014-02-27',
  },
]
