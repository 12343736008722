import React, { CSSProperties, DetailedHTMLProps, FunctionComponent, HTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from 'react'

export const IntoView: FunctionComponent<PropsWithChildren<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>> = ({ children, ...props }) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<CSSProperties>({
    transform: 'translate(0, 200px)',
    opacity: 0,
    transition: 'transform 777ms ease-in-out, opacity 777ms ease-in-out',
  })

  useEffect(() => {
    const rect = rootRef.current!.getBoundingClientRect()
    if (rect.y < 0) {
      setStyle({})
      return
    }

    const observer = new IntersectionObserver(entries => {
      const entry = entries.at(0)
      if (!entry) return
      if (entry.isIntersecting || entry.boundingClientRect.y < 0) {
        setStyle(style => ({
          ...style,
          transform: 'translate(0, 0)',
          opacity: 1,
        }))
        observer.disconnect()
      }
    })

    observer.observe(rootRef.current!)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div ref={rootRef} {...props}>
      <div style={style}>{children}</div>
    </div>
  )
}
