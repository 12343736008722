import { createSelector } from 'reselect'

import { trimRegionalRoute } from '@app/utils/routing/region'
import { RouteComponentProps } from '@app/utils/routing/types'

import { StoreState } from '@app/store/store'

import { availableRegionsSlugsSelector } from './regions'

export type LandingVersion = 'main' | 'urgent_nanny' | 'part_time_nanny' | 'coolest' | 'safety' | 'nanny' | 'camp' | 'online'

const pathnameSelector = (_state: StoreState, props: { location: { pathname: string } }) => props.location.pathname

/**
 * Site view, whether it pointed on sitters or on parents.
 * One edge case is "parent-nanny" that is "/nanny" landing version
 */
export const landingVersionSelector = createSelector([availableRegionsSlugsSelector, pathnameSelector], (regions, pathname): LandingVersion => {
  const path = trimRegionalRoute(regions, pathname).substring(1)
  switch (path) {
    case 'urgent-nanny':
      return 'urgent_nanny'
    case 'part-time-nanny':
      return 'part_time_nanny'
    case 'safety':
      return 'safety'
    case 'nanny':
      return 'nanny'
    case 'camp':
      return 'camp'
    case 'online':
      return 'online'
    default:
      return 'main'
  }
})

export type View = 'parent' | 'parent_nanny' | 'sitter'

/**
 * Site view, whether it pointed on sitters or on parents.
 * One edge case is "parent-nanny" that is "/nanny" landing version
 */
export const viewSelector = (_state: StoreState, props: RouteComponentProps): View => {
  if (/\/nanny(\/.*)?/.test(props.location.pathname)) {
    return 'parent_nanny'
  }
  return /\/babysitter(\/.*)?/.test(props.location.pathname) ? 'sitter' : 'parent'
}
