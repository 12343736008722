import React, { FunctionComponent, useMemo } from 'react'

import { Accordion, AccordionItem } from '@app/components/Accordion/Accordion'
import { AccordionStyle } from '@app/components/Accordion/AccordionStyle'
import { MarkdownRenderer } from '@app/components/MarkdownRenderer/MarkdownRenderer'

import classes from './FaqAccordion.module.scss'

export interface FaqAccordionProps {
  questions: { question: string; answer: { md: string } }[]
  style?: AccordionStyle
  expandFirstItem?: boolean
}

const expandedItems = ['0']

export const FaqAccordion: FunctionComponent<FaqAccordionProps> = ({ questions, style, expandFirstItem = true }) => {
  const accordionItems = useMemo<AccordionItem[]>(
    () =>
      questions.map((q, i) => ({
        id: String(i),
        title: q.question,
        content: <MarkdownRenderer content={q.answer.md} />,
      })),
    [questions]
  )

  if (!accordionItems.length) return null

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className={classes.list}
      items={accordionItems}
      preExpanded={expandFirstItem ? expandedItems : undefined}
      style={style}
    />
  )
}
