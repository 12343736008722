import React, { FunctionComponent, ReactNode, useMemo } from 'react'
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
  Accordion as ReactAccordion,
  AccordionItem as ReactAccordionItem,
} from 'react-accessible-accordion'

import { Icon } from '@app/components/Icon/Icon'

import { AccordionStyle, AccordionStyleFlash } from './AccordionStyle'

import 'react-accessible-accordion/dist/fancy-example.css'

export interface AccordionItem {
  id: string
  title: ReactNode
  content: ReactNode
}

export interface AccordionProps {
  className?: string
  items: AccordionItem[]
  allowMultipleExpanded?: boolean
  allowZeroExpanded?: boolean
  preExpanded?: string[]
  style?: AccordionStyle
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  className,
  items,
  preExpanded,
  allowMultipleExpanded = true,
  allowZeroExpanded = true,
  style = AccordionStyleFlash.instance,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const expanded = useMemo(() => preExpanded, [])

  if (!items.length) return null

  return (
    <ReactAccordion
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded={allowZeroExpanded}
      className={cn(style.root(), className)}
      preExpanded={expanded}
    >
      {items.map(item => (
        <ReactAccordionItem className={style.item()} key={item.id} uuid={item.id}>
          <AccordionItemState>
            {({ expanded }) => (
              <React.Fragment>
                <AccordionItemHeading className={cn(style.title())}>
                  <AccordionItemButton>
                    {item.title}
                    <Icon className={style.icon({ expanded: !!expanded })} icon="chevron-up" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={style.body({ hidden: !expanded })}>{item.content}</AccordionItemPanel>
              </React.Fragment>
            )}
          </AccordionItemState>
        </ReactAccordionItem>
      ))}
    </ReactAccordion>
  )
}
