import { AccordionStyle } from '@app/components/Accordion/AccordionStyle'

import classes from './AccordionStyleFAQ.module.scss'

export class AccordionStyleFAQ implements AccordionStyle {
  body({ hidden }: { hidden: boolean }): string {
    return cn(classes.accordion_body, { [classes.is_hidden]: hidden })
  }
  icon({ expanded }: { expanded: boolean }): string {
    return cn(classes.accordion_icon, { [classes.is_expanded]: expanded })
  }
  item(): string {
    return classes.accordion_item
  }
  root(): string {
    return ''
  }
  title(): string {
    return cn(classes.accordion_title)
  }

  static instance = new AccordionStyleFAQ()
}
